import { makeStyles, TableCell, TableRow } from '@material-ui/core'
import { useTableFooter } from 'components/Dashboard/style'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'
import React, { FC } from 'react'
import { ISyndicatorReportResponse } from 'typescript/interfaces/syndicators'
import { convertToPriceFormat, convertToPriceFormatWithoutCents, formatNegativePrice } from 'utils/formatters'
import cn from 'classnames'

const useStyles = makeStyles((theme) => ({
  row: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  cell: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
  },
}))

interface ISyndicatorReportTableFooterProps {
  stats?: ISyndicatorReportResponse
}

const SyndicatorReportTableFooter: FC<ISyndicatorReportTableFooterProps> = ({ stats }) => {
  const s = useStyles()
  const classes = useTableFooter()
  const additionalClasses = useAdditionalMaterialStyle()
  return (
    <TableRow className={s.row}>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)} colSpan={6}>
        Total:
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
        {convertToPriceFormat(stats?.totalDealAdvance)}
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
        {convertToPriceFormat(stats?.totalToDatePayback)}
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
        {convertToPriceFormat(stats?.totalProjPayback)}
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)} />
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
        {convertToPriceFormat(stats?.totalSyndicated)}
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
        {convertToPriceFormat(stats?.totalDealCollected)}
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
        {convertToPriceFormat(stats?.totalCollected)}
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
        {convertToPriceFormat(stats?.totalDealCollected)}
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
        {convertToPriceFormat(stats?.totalCollected)}
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
        {convertToPriceFormat(stats?.totalDealRemaining)}
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
        {convertToPriceFormat(stats?.totalRemaining)}
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)}>
        {formatNegativePrice(stats?.totalPnl || 0, convertToPriceFormatWithoutCents)}
      </TableCell>
      <TableCell className={cn(classes.cellStyle, classes.footerTableCell, additionalClasses.boldText)} />
    </TableRow>
  )
}

export default SyndicatorReportTableFooter
