import React, { FC, useCallback, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/ArrowLeftIcon.svg'
import Button from 'UI/Button/ButtonWithPreloader'
import Paper from '@material-ui/core/Paper'
import { ReactComponent as EditPenIcon } from 'assets/svg/EditPenIcon.svg'
import Grid from '@material-ui/core/Grid'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { TableIsoDocuments, useIso } from 'hooks/useIso'
import { ReactComponent as ExpandIcon } from 'assets/svg/ExpandIcon.svg'

import { useMutation, useQuery, useQueryCache } from 'react-query'
import CircularProgress from '@material-ui/core/CircularProgress'
import { SYNDICATORS_EDIT_URL, SYNDICATOR_REPORT_URL, TRANSACTIONS_URL, WITHDRAWAL_URL, UPLOAD_USER_PAGE_DOCS } from 'constants/routes'
import BusinessInfo from 'components/CompanyInfo/BusinessInfo'
import { getSyndicator, deleteFileFromSyndicator, getSyndicatorReport } from 'services/syndicators'
import { convertEmptyField } from 'utils/formatters'
import { SvgIcon } from '@material-ui/core'
import { ReactComponent as UploadIcon } from 'assets/svg/UploadIcon.svg'
import ConfirmationContent from 'UI/Modal/ConfirmationContent'
import ModalComponent from 'UI/Modal'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { useSnackbar } from 'notistack'
import { getFile } from 'services/storage'
import { createLinkToDownLoad } from 'utils/downloadFile'

import { usePaginationList } from 'hooks/usePaginationList'
import { TableSyndicatorReport, useSyndicatorReport } from 'hooks/useSyndicatorReport'
import { ISyndicatorReportListItem, SyndicatorDealsSortingFields } from 'typescript/interfaces/syndicators'
import { usePermission } from 'hooks/usePermission'
import { SYNDICATOR_CREATE_PERM, SYNDICATORS_MODULE_PERM, UPLOAD_USER_PAGE_DOCS_PERM } from 'constants/permissions'
import UserPageInfo from 'components/UserPageInfo'
import { useAdditionalMaterialStyle } from 'containers/MaterialUiContainer/additionalStyles'

const SyndicatorInfoContainer: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { goBack, push } = useHistory()
  const aStyle = useAdditionalMaterialStyle()
  const { hasPermission, isOwnSyndicator } = usePermission()
  const queryCache = useQueryCache()
  const [deleteFileState, setDeleteFileState] = useState({
    id: 0,
    open: false,
  })
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useQuery(['syndicatorById', id], () => getSyndicator(id))

  const { columns: syndicatorsColumns } = useSyndicatorReport()

  const { setPagination, handleFetchMore, handleSort, pagination } = usePaginationList<ISyndicatorReportListItem>({
    initState: {
      data: [],
      search: '',
      order: SyndicatorDealsSortingFields.deal,
      sortOrder: 'DESC',
      page: 0,
      hasMore: true,
    },
  })

  const [getSyndicatorReportMut, { isLoading: isDealsLoading }] = useMutation(getSyndicatorReport, {
    onSuccess: (syndicatorReportData) => {
      setPagination((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...syndicatorReportData.data.syndicatorReportList],
      }))
    },
  })

  useEffect(() => {
    if (data?.data.id) {
      getSyndicatorReportMut({
        PageSize: 5,
        SyndicatorId: `${data.data.id}`,
        PageIndex: pagination.page,
        SortField: pagination.order,
        SortOrder: pagination.sortOrder,
      })
    }
  }, [pagination.page, pagination.order, pagination.sortOrder, data?.data.id])

  const [removeDoc, { isLoading: isLoadingRemove }] = useMutation(deleteFileFromSyndicator, {
    onSuccess: (_, variables) => {
      setDeleteFileState({
        open: false,
        id: 0,
      })
      queryCache.setQueryData(['syndicatorById', id], {
        ...data,
        data: {
          ...data?.data,
          documents: data?.data.documents.filter((d) => d.id !== variables),
        },
      })
      enqueueSnackbar('Successfully deleted document')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const handleDownLoadFile = useCallback((url, name) => {
    getFile(url).then((res) => {
      createLinkToDownLoad(res.data, name)
    })
  }, [])

  const { columnsIsoDocuments } = useIso({
    handleDeleteFile: (idFile) => {
      setDeleteFileState({
        id: idFile,
        open: true,
      })
    },
    handleDownloadFile: handleDownLoadFile,
    canDelete: hasPermission(SYNDICATORS_MODULE_PERM),
  })

  return (
    <Box className={aStyle.wrapper}>
      {isLoading || !data ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Box ml="3rem" mr="3rem" display="flex" alignItems="center">
            {hasPermission(SYNDICATORS_MODULE_PERM) && (
              <Box mr="1.5rem">
                <IconButton color="inherit" onClick={() => goBack()}>
                  <ArrowLeftIcon />
                </IconButton>
              </Box>
            )}
            <Typography color="textSecondary" variant="h1">
              {data.data.businessName}
            </Typography>
            <Box flex="1" display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  push(
                    generatePath(TRANSACTIONS_URL, {
                      id: data.data.id,
                      type: 'syndicator',
                      businessName: data.data.businessName,
                    }),
                  )
                }
              >
                Activity
              </Button>
              <Box ml="1rem">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    push(
                      generatePath(WITHDRAWAL_URL, {
                        id: data.data.id,
                        type: 'syndicator',
                        businessName: data.data.businessName,
                      }),
                    )
                  }
                >
                  Withdrawals
                </Button>
              </Box>
            </Box>
          </Box>
          <Box mt="2rem">
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <Box width="100%" display="flex" justifyContent="flex-end" p="1rem" position="relative">
                    {hasPermission(SYNDICATOR_CREATE_PERM) && (
                      <IconButton
                        className={aStyle.absoluteEditButton}
                        size="small"
                        onClick={() => push(SYNDICATORS_EDIT_URL.replace(':id', `${data.data.id}`))}
                      >
                        <EditPenIcon />
                      </IconButton>
                    )}
                  </Box>
                  <Box pb="2rem" px="3rem">
                    <Box mb="2rem">
                      <Typography variant="h3" color="textSecondary">
                        General Information
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        container
                        xs={6}
                        spacing={3}
                        style={{
                          alignContent: 'flex-start',
                        }}
                      >
                        <Grid item xs={6}>
                          <Typography>First Name</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.firstName)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Last Name</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.lastName)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Phone</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.phone)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Mobile</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.mobile)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Email</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.email)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Fax</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.fax)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Label</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.label)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Management Fee</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{convertEmptyField(data.data.managementFee)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Gets Bank fee</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary">{data.data.getsBankFee ? 'Yes' : 'No'}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item container xs={6} spacing={3}>
                        <UserPageInfo userPage={data.data.userPage} nameOnCheck={data.data.nameOnCheck} />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <BusinessInfo
                    street={data.data.street}
                    businessName={data.data.businessName}
                    city={data.data.city}
                    state={data.data.state}
                    federalTaxId={data.data.federalTaxId}
                    zipCode={data.data.zipCode}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box mt={5}>
            <Paper elevation={0}>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <Box p="3rem">
                  <Typography variant="h2" color="textSecondary">
                    Syndication deals
                  </Typography>
                </Box>
                <Box p="1rem">
                  {/* {hasPermission(SYNDICATORS_MODULE_PERM) && ( */}
                  <IconButton onClick={() => push(SYNDICATOR_REPORT_URL.replace(':id', `${data.data.id}`))} size="small">
                    <SvgIcon fontSize="small" viewBox="0 0 16 16" component={ExpandIcon} />
                  </IconButton>
                  {/* )} */}
                </Box>
              </Box>
              <Box>
                <TableSyndicatorReport
                  columns={syndicatorsColumns}
                  handleSortChange={handleSort}
                  loading={isDealsLoading}
                  handleGetMore={handleFetchMore}
                  data={pagination.data}
                />
              </Box>
            </Paper>
          </Box>
          <Box mt={5}>
            <Paper elevation={0}>
              <Box p="3rem" display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h3" color="textSecondary">
                  Documents
                </Typography>
                <Box>
                  {(hasPermission(UPLOAD_USER_PAGE_DOCS_PERM) || isOwnSyndicator(data?.data.id)) && (
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<UploadIcon />}
                      onClick={() =>
                        push(
                          generatePath(UPLOAD_USER_PAGE_DOCS, {
                            type: 'syndicator',
                            id: data.data.id,
                          }),
                        )
                      }
                    >
                      Upload documents
                    </Button>
                  )}
                </Box>
              </Box>
              <Box>
                <TableIsoDocuments columns={columnsIsoDocuments} data={data.data.documents} />
              </Box>
            </Paper>
          </Box>
        </>
      )}
      <ModalComponent open={deleteFileState.open}>
        <ConfirmationContent
          text="Are you sure you want delete file?"
          isLoading={isLoadingRemove}
          handleCancel={() =>
            setDeleteFileState({
              open: false,
              id: 0,
            })
          }
          handleConfirm={() => removeDoc(deleteFileState.id)}
        />
      </ModalComponent>
    </Box>
  )
}

export default SyndicatorInfoContainer
